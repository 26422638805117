import Lottie from "lottie-react";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MetaTags from "react-meta-tags";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { useLongPress } from "use-long-press";
import explodeLoader from "../data/explode-loader.json";
import organicLoader from "../data/organic-loader.json";
import waveLoader from "../data/wave-loader.json";

import { Auth } from "../api/auth";
import { OverlayBlack1 } from "../components/Overlays";
import { SystemFooter } from "../components/SystemFooter";
import { Tos } from "../components/Tos";
import { TosFooter } from "../components/TosFooter";

const FinalTypeWriter = styled(Typewriter)`
  opacity: 1;
`;

const FormWrapper = styled.div`
  @keyframes fadeItt {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeItt 2000ms ease-in-out 1;
  animation: fadeItt 2000ms ease-in-out 1;
`;

const Form = styled.form`
  @keyframes fadeGenerateButton {
    0% {
      opacity: 0;
    }

    90% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeGenerateButton 2600ms ease-in-out 1;
  animation: fadeGenerateButton 2600ms ease-in-out 1;
`;

const Form2 = styled.form`
  @keyframes fadeGenerateButton2 {
    0% {
      opacity: 0;
    }

    90% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeGenerateButton2 1500ms ease-in-out 1;
  animation: fadeGenerateButton2 1500ms ease-in-out 1;
`;

const Form3 = styled.form`
  @keyframes fadeGenerateButton3 {
    0% {
      opacity: 0;
    }

    96% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeGenerateButton3 4900ms ease-in-out 1;
  animation: fadeGenerateButton3 4900ms ease-in-out 1;
`;

const Attempts = styled.div`
  @keyframes fadeNumAttempts {
    0% {
      opacity: 0;
    }

    90% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeNumAttempts 2000ms ease-in 1;
  animation: fadeNumAttempts 2000ms ease-in 1;
`;

const BotImg = styled.img``;

const BotImg2 = styled.img``;

const BotImg2Wrapper = styled.div``;

const CreditsFade1 = styled.span`
  @keyframes fadeCredits {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeCredits 120ms ease-out 1;
  animation: fadeCredits 120ms ease-out 1;
`;

const CreditsFade2 = styled.form`
  @keyframes fadeCredits2 {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeCredits2 120ms ease-out 1;
  animation: fadeCredits2 120ms ease-out 1;
`;

export const OverlayCredits = styled.div`
  line-height: 18px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 999;
  overflow: scroll;
  font-size: 80%;
`;

const OrganicWrapper = styled.div`
  max-height: 440px;
`;

async function shareImage(images = []) {
  const filenames = images.map((it) => it.substring(it.lastIndexOf("/") + 1));
  const results = await Promise.all(images.map((it) => fetch(it)));
  const blobs = await Promise.all(results.map((it) => it.blob()));
  const filesArray = blobs.map(
    (blob, ix) =>
      new File([blob], filenames[ix], {
        type: "image/png",
        lastModified: new Date().getTime(),
      })
  );
  const shareData = {
    files: filesArray,
  };
  navigator.share(shareData);
}

const Portfolio = () => {
  const [selectedToken, setSelectedToken] = useState();
  const [attemptsRemaining, setAttemptsRemaining] = useState();
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorCritical, setApiErrorCritical] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    Auth.read().tokenTransferred
      ? 4
      : Auth.read().claimedToken
      ? Auth.read().tokenTransferred
        ? 0
        : 3
      : 1
  );
  const [showWave, setShowWave] = useState(false);
  const [availableTokens, setAvailableTokens] = useState(Auth.read().tokens);
  const [email, setEmail] = useState(Auth.read().code);
  const [claimedToken, setClaimedToken] = useState(Auth.read().claimedToken);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();
  const [disallowSubmit, setDisallowSubmit] = useState(false);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [showCreditsOverlay, setShowCreditsOverlay] = useState(false);
  const [showTosOverlay, setShowTosOverlay] = useState(false);
  const [showFinalTypeWriter, setShowFinalTypeWriter] = useState(false);
  const [showOverlayBlack, setShowOverlayBlack] = useState(false);

  const handleTosClick = (e) => {
    e.preventDefault();
    setShowTosOverlay(true);
  };

  const handleHideTosOverlay = (e) => {
    e.preventDefault();
    setShowTosOverlay(false);
  };

  const handleShowCredits = (e) => {
    e.preventDefault();
    setShowCreditsOverlay(true);
  };

  const handleHideCredits = (e) => {
    e.preventDefault();
    setShowCreditsOverlay(false);
  };

  const setChosenTokenOnApi = async (token) => {
    try {
      const res = await fetch("https://basel-qncsltdssa-uc.a.run.app", {
        method: "POST",
        headers: {
          Authorization: email,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          claimedToken: token.id,
        }),
      });
      const json = await res.json();
      Auth.read().write(json);
    } catch (e) {
      setApiErrorCritical(true);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisallowSubmit(true);
    setApiError(false);
    try {
      const res = await fetch("https://basel-qncsltdssa-uc.a.run.app", {
        method: "POST",
        headers: {
          Authorization: email,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          claimedToken: selectedToken?.id,
          walletAddress: code,
        }),
      });
      const json = await res.json();
      Auth.read().write(json);

      if (!json.tokenTransferred) {
        setApiError(true);
      } else {
        setCurrentStep(4);
      }
    } catch (e) {
      setApiError(true);
    }

    setLoading(false);
    setTimeout(() => {
      setDisallowSubmit(false);
    }, 3000);
  };

  useEffect(() => {
    if (availableTokens?.length) {
      const indexOfToken = availableTokens.findIndex(
        (it) => it.id === claimedToken
      );

      if (indexOfToken === -1) {
        setAttemptsRemaining(availableTokens.length);
      } else {
        setAttemptsRemaining(availableTokens.length - indexOfToken - 1);
      }
    }
  }, [availableTokens, claimedToken]);

  useEffect(() => {
    if (currentStep === -1) {
      setDisableConfirmButton(true);
      setTimeout(() => {
        setDisableConfirmButton(false);
      }, 4500);
    } else if (currentStep === 2) {
      setDisableConfirmButton(true);
      setTimeout(() => {
        setDisableConfirmButton(false);
      }, 1400);
    } else if (currentStep === 4) {
      setShowOverlayBlack(true);
      setTimeout(() => {
        setShowOverlayBlack(false);
        setShowFinalTypeWriter(true);
      }, 2400);
    }
  }, [currentStep]);

  const callback = useCallback(
    (event) => {
      setAttemptsRemaining(attemptsRemaining - 1);
      setError(false);
      setShowWave(false);
      setShowLoader(true);
      setShowConfetti(true);
      setTimeout(() => {
        setShowLoader(false);
        setError(false);
        setShowConfetti(false);
        setCurrentStep(2);
      }, 1000);
    },
    [attemptsRemaining, error, showLoader, currentStep]
  );
  const bind = useLongPress(callback, {
    onFinish: (event) => {},
    onStart: (event) => {
      setShowWave(true);
    },
    onCancel: (event) => {
      setShowWave(false);
      setError(true);
    },
    threshold: 2000,
    detect: "both",
  });
  const onDone = () => {
    // setShowAttempts(true);
  };

  const onDoneFinalTypewriter = () => {
    setShowCredits(true);
  };

  const handleInputChange = (e) => setCode(e.target.value);

  return (
    <>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>generate | heart terminal</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </MetaTags>
      <BrowserView>
        <section className="spacer m-left-xs m-right-xs p-top-md">
          <div className="wrapper typewriter-wrapper" id="typewriter-wrapper">
            <Typewriter
              options={{
                loop: false,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("")
                  .changeDelay(15)
                  .pauseFor(1400)
                  .typeString("Please access system on mobile device.")
                  .start();
              }}
            />
          </div>
        </section>
      </BrowserView>
      <MobileView>
        <main
          id="main"
          className={currentStep < 4 ? "triangles site-main" : ""}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          {showCreditsOverlay ? (
            <OverlayCredits>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  padding: "24px",
                  fontSize: "200%",
                  fontWeight: "1000",
                  transform: "scale(1, .8547)",
                  cursor: "pointer",
                }}
                onClick={handleHideCredits}
              >
                X
              </div>
              <div className="page-content spacer p-top-md">
                <div className="wrapper spacer p-top-md">
                  <div className="content">
                    <div className="clearfix">
                      Concept, Design, and Direction
                      <br />
                      Gregory X<br />
                      <br />
                      Development
                      <br />
                      Brian Lewis
                      <br />
                      <br />
                      Additional Art Assets
                      <br />
                      John Dill
                      <br />
                      <br />
                      Animations
                      <br />
                      Hùng Chềnh
                      <br />
                      Harish Prajapat
                      <br />
                      <br />
                      Art Patron & Support
                      <br />
                      Swamy
                      <br />
                      <br />
                      <span style={{ fontWeight: "1000" }}>Thank You</span>
                      <br />
                      Robot Heart Foundation,
                      <br />
                      the entire Robot Heart family,
                      <br />
                      and most importantly, our
                      <br />
                      community, guests, and
                      <br />
                      supporters.
                    </div>
                  </div>
                </div>
              </div>
            </OverlayCredits>
          ) : null}
          {showTosOverlay ? <Tos onClick={handleHideTosOverlay}></Tos> : null}
          <div className="page-content spacer p-top-md">
            <div className="wrapper">
              <div className="content">
                <div className="clearfix">
                  {currentStep === -1 ? (
                    <div className="generator-typewriter-2">
                      <Typewriter
                        id="typewriter-0"
                        options={{
                          loop: false,
                        }}
                        className="generator-typewriter-2"
                        onInit={(typewriter) => {
                          typewriter
                            .callFunction(() => {
                              document
                                .getElementsByClassName("Typewriter__cursor")[0]
                                .classList.add("line-height-28");
                            })
                            .changeDelay(1)
                            .pauseFor(300)
                            .typeString(
                              `<span class="oII">No Ethereum wallet?</span>`
                            )
                            .pauseFor(500)
                            .typeString(`<span class="oII"> No problem.</span>`)
                            .pauseFor(500)
                            .callFunction(() => {
                              document
                                .getElementsByClassName("Typewriter__cursor")[0]
                                .classList.add("hidden-cursor");
                            })
                            .pasteString(`<span class="oII"><br/><br/></span>`)
                            .callFunction(() => {
                              document
                                .getElementsByClassName("Typewriter__cursor")[0]
                                .classList.remove("hidden-cursor");
                            })
                            .typeString(
                              `<span class="oII"> You can download the art to your phone, however authenticity and ownership will not be preserved on the blockchain.</span>`
                            )
                            .start();
                        }}
                      />
                    </div>
                  ) : currentStep === 0 ? (
                    <div className="generator-typewriter-2">
                      <Typewriter
                        id="typewriter-1"
                        options={{
                          loop: false,
                        }}
                        className="generator-typewriter-2"
                        onInit={(typewriter) => {
                          typewriter
                            .changeDelay(15)
                            .typeString(
                              `<span class="HjP">Token<br/>already claimed.</span>`
                            )
                            .pauseFor(1200)
                            .callFunction(() => {
                              document
                                .getElementsByClassName("Typewriter__cursor")[0]
                                .classList.add("hidden-cursor");

                              onDone();
                            })
                            .start();
                        }}
                      />
                    </div>
                  ) : currentStep === 1 ? (
                    <>
                      <div className="generator-typewriter-2">
                        <Typewriter
                          id="typewriter-2"
                          options={{
                            loop: false,
                          }}
                          className="generator-typewriter-2"
                          onInit={(typewriter) => {
                            typewriter
                              .changeDelay(15)
                              .typeString(
                                `<span class="HjP">Begin<br/>construction</span>`
                              )
                              .pauseFor(1200)
                              .callFunction(() => {
                                document
                                  .getElementsByClassName(
                                    "Typewriter__cursor"
                                  )[0]
                                  .classList.add("hidden-cursor");

                                onDone();
                              })
                              .start();
                          }}
                        />
                      </div>
                    </>
                  ) : currentStep === 2 ? (
                    <>
                      <span></span>
                      <div className="generator-typewriter-2">
                        <Typewriter
                          id="typewriter-3"
                          options={{
                            loop: false,
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .changeDelay(1)
                              .typeString(
                                `<span class="HjP">Generation<br/>complete</span>`
                              )
                              .pauseFor(1200)
                              .callFunction(() => {
                                document
                                  .getElementsByClassName(
                                    "Typewriter__cursor"
                                  )[0]
                                  .classList.add("hidden-cursor");

                                onDone();
                              })
                              .start();
                          }}
                        />
                      </div>
                    </>
                  ) : currentStep === 3 ? (
                    <>
                      {apiErrorCritical ? (
                        <p className="text after login-text-after">
                          <span className="login-red">
                            ERROR: CRITICAL ERROR
                          </span>
                        </p>
                      ) : (
                        <>
                          <div
                            className="generator-typewriter-2"
                            id="typewriter-4-wrapper"
                            style={{ minHeight: "71px" }}
                          >
                            <Typewriter
                              id="typewriter-4"
                              style={{ minHeight: "71px" }}
                              options={{
                                loop: false,
                              }}
                              onInit={(typewriter) => {
                                typewriter
                                  .pauseFor(400)
                                  .changeDelay(1)
                                  .typeString(
                                    `<span class="HjP">Claim and save your</span><br/><span class="HjP">bot gift.</span>`
                                  )
                                  .pauseFor(1200)
                                  .callFunction(() => {
                                    document
                                      .getElementsByClassName(
                                        "Typewriter__cursor"
                                      )[0]
                                      .classList.add("hidden-cursor");

                                    onDone();
                                  })
                                  .start();
                              }}
                            />
                          </div>
                          <div className="title spacer p-top-sm m-left-xl m-right-xl">
                            <BotImg2Wrapper
                              style={{
                                padding: "12px",
                                border: "1px solid #fff",
                              }}
                            >
                              <BotImg2
                                src={
                                  availableTokens?.[
                                    availableTokens?.length -
                                      attemptsRemaining -
                                      1
                                  ]?.imgUrl
                                }
                                alt="Your bot"
                                style={{ width: "100%" }}
                              />
                            </BotImg2Wrapper>
                          </div>
                          <FormWrapper>
                            <section className="spacer p-top-sm">
                              {apiError ? (
                                <p className="text after login-text-after">
                                  <span className="login-red">
                                    ERROR: PLEASE CHECK YOUR WALLET ADDRESS
                                  </span>
                                </p>
                              ) : null}
                              <form
                                onSubmit={onSubmit}
                                id="cf-3"
                                className="contact-form login-form"
                              >
                                <div className="form-group form-group-lg mb-0r">
                                  <input
                                    autoComplete="off"
                                    name="code"
                                    className="form-control form-control-lg"
                                    id="cf-1-code"
                                    placeholder="ETH wallet address"
                                    value={code}
                                    disabled={loading}
                                    style={{ minHeight: "70px" }}
                                    onChange={handleInputChange}
                                  ></input>
                                  <div className="form-group form-group-lg mb-0 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-outline-primary text-uppercase"
                                      style={{
                                        minWidth: "100%",
                                        minHeight: "70px",
                                        marginTop: "1rem",
                                      }}
                                      disabled={
                                        loading || disallowSubmit || !code
                                      }
                                    >
                                      SEND TO WALLET
                                    </button>
                                    <button
                                      onClick={() => {
                                        setCurrentStep(-1);
                                      }}
                                      className="btn btn-outline-primary text-uppercase"
                                      style={{
                                        minWidth: "100%",
                                        minHeight: "70px",
                                        marginTop: "1rem",
                                      }}
                                      disabled={loading || disallowSubmit}
                                    >
                                      DON'T HAVE A WALLET
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </section>
                          </FormWrapper>
                        </>
                      )}
                    </>
                  ) : currentStep === 4 ? (
                    <>
                      {showOverlayBlack ? (
                        <OverlayBlack1></OverlayBlack1>
                      ) : null}
                      <div
                        style={{
                          lineHeight: "29px",
                          minHeight: "58px",
                          display: "block",
                        }}
                      >
                        {showFinalTypeWriter ? (
                          <FinalTypeWriter
                            id="typewriter-5"
                            options={{
                              loop: false,
                            }}
                            onInit={(typewriter) => {
                              typewriter
                                .changeDelay(1)
                                .pauseFor(800)
                                .typeString(
                                  `<span class="uaV login-yellow">Request acknowledged.</span>`
                                )
                                .pauseFor(1200)
                                .typeString(
                                  `<br/><span class="uaV">Thank you.</span>`
                                )
                                .pauseFor(800)
                                .callFunction(() => {
                                  document
                                    .getElementsByClassName(
                                      "Typewriter__cursor"
                                    )[0]
                                    .classList.add("hidden-cursor");

                                  onDone();
                                })
                                .start();
                            }}
                          />
                        ) : null}
                      </div>
                      <OrganicWrapper className="spacer m-left-lg m-right-lg">
                        <div className="spacer m-left-md m-right-md">
                          <Lottie animationData={organicLoader} loop={true} />
                        </div>
                      </OrganicWrapper>
                      <div id="typewriter-6">
                        <Typewriter
                          options={{
                            loop: false,
                          }}
                          onInit={(typewriter) => {
                            typewriter

                              .changeDelay(1)
                              .typeString(`<br/><span class="ttR"> </span>`)
                              .pauseFor(6000)
                              .callFunction(() => {
                                document
                                  .getElementsByClassName(
                                    "Typewriter__cursor"
                                  )[1]
                                  .classList.add("visible-cursor");
                              })
                              .pauseFor(800)
                              .changeDelay(15)
                              .typeString(`<span class="ttR">Always</span>`)
                              .changeDelay(30)
                              .typeString(`<span class="ttR"> fight</span>`)
                              .changeDelay(45)
                              .typeString(`<span class="ttR"> for</span>`)
                              .changeDelay(60)
                              .typeString(`<span class="ttR"> the</span>`)
                              .changeDelay(75)
                              .typeString(`<span class="ttR"> user.</span>`)
                              .pauseFor(800)
                              .callFunction(() => {
                                document
                                  .getElementsByClassName(
                                    "Typewriter__cursor"
                                  )[1]
                                  .classList.remove("visible-cursor");
                              })
                              .callFunction(() => {
                                onDoneFinalTypewriter();
                              })
                              .start();
                          }}
                        />
                      </div>
                      {showCredits ? (
                        <CreditsFade1
                          style={{
                            display: "block",
                            lineHeight: "58px",
                            textDecoration: "underline",
                          }}
                          onClick={handleShowCredits}
                        >
                          Credits
                        </CreditsFade1>
                      ) : null}
                    </>
                  ) : null}

                  {currentStep === 1 || currentStep === 2 ? (
                    <Attempts
                      style={{ marginTop: "8px", marginBottom: "12px" }}
                    >
                      {attemptsRemaining} attempt
                      {attemptsRemaining !== 1 ? "s" : ""} remaining
                    </Attempts>
                  ) : null}

                  <div className="wrapper bot-button">
                    {currentStep === 2 ? (
                      <div className="title spacer m-left-lg m-right-lg">
                        <div className="bot-id">
                          <BotImg
                            src={
                              availableTokens[
                                availableTokens.length - attemptsRemaining - 1
                              ].imgUrl
                            }
                            alt="Your bot"
                            style={{ width: "100%" }}
                          />
                          <span>
                            BOT ID:{" "}
                            {availableTokens[
                              availableTokens.length - attemptsRemaining - 1
                            ]?.id?.slice(-8)}
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {showWave ? (
                      <div className="title spacer p-top-sm">
                        <Lottie animationData={waveLoader} />
                      </div>
                    ) : showConfetti ? (
                      <div className="title spacer p-top-sm">
                        <Lottie animationData={explodeLoader} />
                      </div>
                    ) : (
                      <div className="title spacer p-top-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 256 256"
                          width="256"
                          height="256"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        ></svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {!showTosOverlay ? (
          <footer id="footer-form" className="site-footer form-footer">
            <div className="wrapper" style={{ paddingBottom: "0" }}>
              <div className="footer">
                <div className="align-self-center">
                  {error ? (
                    <p
                      className="text after login-text-after"
                      style={{ textAlign: "center", marginBottom: "24px" }}
                    >
                      <span className="login-yellow">
                        Hold button to generate bot.
                      </span>
                    </p>
                  ) : null}
                  {currentStep === -1 ? (
                    <Form3
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      id="cf-2"
                      className="contact-form login-form"
                    >
                      <div className="form-group form-group-lg mb-0r">
                        <div
                          className="form-group form-group-lg mb-0"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            disabled={disableConfirmButton}
                            onClick={async (e) => {
                              e.preventDefault();
                              setCurrentStep(4);
                            }}
                            type="submit"
                            className="btn btn-outline-primary text-uppercase step-2-button"
                          >
                            Continue
                          </button>
                          <button
                            disabled={disableConfirmButton}
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentStep(3);
                            }}
                            type="submit"
                            style={{ marginTop: "1em" }}
                            className="btn btn-outline-primary text-uppercase step-2-button"
                          >
                            {"< "}Back to wallet entry
                          </button>
                        </div>
                      </div>
                    </Form3>
                  ) : currentStep === 1 ? (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      id="cf-1"
                      className="contact-form login-form"
                    >
                      <div className="form-group form-group-lg mb-0r">
                        <div
                          className="form-group form-group-lg mb-0"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            disabled={showLoader ? true : false}
                            {...bind()}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            type="submit"
                            className="btn btn-outline-primary text-uppercase shimmer-button"
                            style={
                              showLoader
                                ? { background: "rgba(255, 255, 255, 0.25)" }
                                : { background: "none" }
                            }
                          >
                            Hold to Generate Bot
                          </button>
                        </div>
                      </div>
                    </Form>
                  ) : currentStep === 2 ? (
                    <Form2
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      id="cf-2"
                      className="contact-form login-form"
                    >
                      <div className="form-group form-group-lg mb-0r">
                        <div
                          className="form-group form-group-lg mb-0"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            disabled={disableConfirmButton}
                            onClick={async (e) => {
                              e.preventDefault();
                              setDisableConfirmButton(true);
                              setSelectedToken(
                                availableTokens[
                                  availableTokens.length - attemptsRemaining - 1
                                ]
                              );
                              await setChosenTokenOnApi(
                                availableTokens[
                                  availableTokens.length - attemptsRemaining - 1
                                ]
                              );
                              setDisableConfirmButton(false);
                              setCurrentStep(3);
                            }}
                            type="submit"
                            className="btn btn-outline-primary text-uppercase step-2-button"
                            style={{ background: "rgba(255,255,255,.5)" }}
                          >
                            Confirm
                          </button>
                          {attemptsRemaining > 0 ? (
                            <button
                              disabled={disableConfirmButton}
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentStep(1);
                              }}
                              type="submit"
                              style={{ marginTop: ".5em" }}
                              className="btn btn-outline-primary text-uppercase step-2-button"
                            >
                              Regenerate
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </Form2>
                  ) : currentStep === 4 && showCredits ? (
                    <CreditsFade2
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      id="cf-3"
                      className="contact-form login-form"
                    >
                      <div className="form-group form-group-lg mb-0r">
                        <div
                          className="form-group form-group-lg mb-0"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            onClick={async (e) => {
                              e.preventDefault();
                              const hasDoneTransfer =
                                Auth.read().tokenTransferred;

                              if (hasDoneTransfer) {
                                const url1 = availableTokens[0]?.dlUrl || "";
                                const url2 = availableTokens[1]?.dlUrl || "";
                                try {
                                  await shareImage([url1, url2]);
                                } catch (e) {}
                              } else {
                                const url1 =
                                  availableTokens.find(
                                    (token) => token.id === claimedToken
                                  ).dlUrl || "";
                                try {
                                  await shareImage([url1]);
                                } catch (e) {}
                              }
                            }}
                            type="submit"
                            className="btn btn-outline-primary text-uppercase step-2-button"
                          >
                            DOWNLOAD MY GIFT
                            {Auth.read().tokenTransferred ? "S" : ""}
                          </button>
                        </div>
                      </div>
                    </CreditsFade2>
                  ) : null}
                </div>
              </div>
            </div>
          </footer>
        ) : null}

        {(currentStep === 3 || currentStep === -1) && !showTosOverlay ? (
          <TosFooter onClick={handleTosClick}></TosFooter>
        ) : !showTosOverlay ? (
          <SystemFooter></SystemFooter>
        ) : null}
      </MobileView>
    </>
  );
};

export default Portfolio;
