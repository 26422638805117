import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export const TosOverlay = styled.div`
  line-height: 18px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 999;
  overflow: scroll;
  font-size: 80%;
`;

export const Tos = ({ onClick }) => (
  <TosOverlay style={{ paddingBottom: "80px" }}>
    <div
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        padding: "24px",
        fontSize: "200%",
        fontWeight: "1000",
        transform: "scale(1, .8547)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      X
    </div>
    <div className="page-content spacer p-top-md">
      <div className="wrapper spacer p-top-md">
        <div className="content">
          <div className="clearfix">
            <span style={{ fontSize: "140%", fontWeight: "1000" }}>
              Art Terms and Conditions of Use
            </span>
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              1. Terms
            </span>
            <br />
            <br />
            By accessing this Website experience (heartterminal.com) you are
            agreeing to be bound by these Terms and Conditions of Use and agree
            that you are responsible for the agreement with any applicable local
            laws. If you disagree with any of these terms, you are prohibited
            from accessing this site. The materials contained in this Website
            are protected by copyright and trade mark law.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              2. Use License
            </span>
            <br />
            <br />
            These unique works of art are conveyed to you as a gift. Permission
            is granted to receive a copy of the works presented to you from this
            website for personal, non-commercial use. You may receive either
            directly via an email you supply or a provided Ethereum wallet
            address or both. You may transfer, resell, and display the work as
            you see fit. This is the grant of a license, not a transfer of
            title, and under this license you may not:
            <br />
            <br />
            • Use the materials for any commercial or business purpose
            <br />
            • Attempt to reverse engineer any software contained on foundation’s
            website or experience
            <br />
            • Remove any copyright or other proprietary notations from the
            materials
            <br />
            <br />
            The brand, the logo, and all works and derivatives of Robot Heart
            are, and remain, under the ownership of Robot Heart and the Robot
            Heart Foundation. Should you violate the terms, we reserve the right
            to terminate your grant of license. Your viewing right will also
            terminate and you should destroy any downloaded materials in your
            possession.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              3. Disclaimer
            </span>
            <br />
            <br />
            All the materials are provided “as is”. The Foundation, its members,
            partners, and organizers make no warranties, expressed or implied,
            on the functioning of the website, compatibility with your device,
            delivery of the works or materials, or any conflicts therein.
            Furthermore, The Foundation does not make any representations
            concerning the accuracy or reliability of the use of the materials
            on its Website or otherwise relating to such materials or any sites
            linked to this Website.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              4. Limitations
            </span>
            <br />
            <br />
            The Robot Heart Foundation or its suppliers, partners, members, and
            organizers will not be held accountable for any damages that will
            arise with the use or inability to use the materials, art, or
            experience on the Website, even if The Foundation or an authorized
            representative of this Website has been notified, orally or written,
            of the possibility of such damage.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              5. Revisions and Errata
            </span>
            <br />
            <br />
            The materials appearing on the Website may include technical,
            typographical, or photographic errors. The Foundation will not
            promise that any of the materials in this Website are accurate,
            complete, or current. The Foundation may change the materials
            contained on its Website at any time without notice. The Foundation
            does not make any commitment to update the materials.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              6. Site Terms of Use Modifications
            </span>
            <br />
            <br />
            The Foundation or its authorized members may revise these Terms of
            Use for its Website at any time without prior notice. By using this
            Website, you are agreeing to be bound by the current version of
            these Terms and Conditions of Use.
            <br />
            <br />
            <span style={{ fontSize: "120%", fontWeight: "1000" }}>
              7. Governing Law
            </span>
            <br />
            <br />
            Any claim related to the Website shall be governed by the laws of
            Country without regards to its conflict of law provisions.
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </TosOverlay>
);
